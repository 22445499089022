import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class ElectronicSingCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>Términos y condiciones</strong><br/>Divulgación de firma electrónica (E-Sign)<br/></span>,
            txtTitle=<span>Esta Divulgación de firma electrónica se aplica a cada cuenta y servicio al que haya accedido o seleccionado en la página web de Apoyo Financiero, Inc. (AFI). Lea atentamente esta Divulgación de firma electrónica y guarde una copia para sus registros.<br/></span>,
            txtContent=<span><strong>1. Entrega electrónica de divulgaciones</strong><br/><br/>Al aceptar estos términos, acepta que AFI puede proporcionarle divulgaciones y/o avisos legales en formato electrónico, a menos y hasta que retire su consentimiento como se describe a continuación.<br/></span>,
            txtTitleEmp='',
            txtContentValors=<span><strong>2. Método para proporcionarle divulgaciones en forma electrónica</strong><br/></span>,
            txtContentOne=<span>
                                Todos los avisos y divulgaciones que le proporcionamos en formato electrónico se proporcionarán:
                                <br/>
                                <ol type="A">
                                <li>En papel,<br/><br/></li>
                                <li>Por correo electrónico, o<br/><br/></li>
                                <li>Mediante el acceso a un sitio web de AFI que designaremos con anticipación para proporcionarle una notificación por correo electrónico que le enviemos en el momento en que la información esté disponible según lo permita la ley.<br/></li>
                                </ol>
                            </span>,
            txtContentTwo=<span><strong>3. Requisitos de hardware y software para acceder y conservar registros</strong><br/></span>,
            txtContentThree=<span>
                                Para acceder, ver y conservar las divulgaciones electrónicas y otras notificaciones que AFI pone a su disposición, debe tener el siguiente hardware y software:
                                <br/>
                                <ol type="a">
                                <li>Un navegador de Internet que admita el cifrado de 128 bits<br/><br/></li>
                                <li>Suficiente capacidad de almacenamiento electrónico en el disco duro de su computadora u otra unidad de almacenamiento de datos<br/><br/></li>
                                <li>Adobe Acrobat Reader 6.1 o superior<br/><br/></li>
                                <li>Una cuenta de correo electrónico con un proveedor de servicios de Internet y software de correo electrónico para recibir comunicaciones electrónicas de AFI<br/><br/></li>
                                <li>Una computadora personal, un sistema operativo y conexiones de telecomunicaciones a Internet capaces de recibir, acceder, mostrar e imprimir o almacenar las comunicaciones recibidas de AFI en forma electrónica a través de un correo electrónico con formato de texto sin formato o mediante el acceso a nuestro sitio web de AFI usando uno de los navegadores especificados anteriormente<br/><br/></li>
                                <li>Para retener información y documentos electrónicos, su sistema/dispositivo debe tener la capacidad de descargar y almacenar archivos PDF.<br/></li>
                                </ol>
                            </span>,
            txtContentFour=<span><strong>4. Cómo retirar su consentimiento</strong><br/><br/>Puede retirar su consentimiento para recibir avisos y divulgaciones en forma electrónica para cualquiera de sus cuentas y servicios comunicándose con nosotros al 1-800-891-2778. No impondremos una tarifa para procesar el retiro de su consentimiento para recibir notificaciones y divulgaciones electrónicas. Cualquier retiro de su consentimiento para recibir notificaciones y divulgaciones electrónicas será efectivo solo después de que tengamos un período razonable para procesar su retiro.<br/></span>,
            txtHistoryTwo=<span><strong>5. Cómo actualizar su información de contacto</strong><br/><br/>Es su responsabilidad proporcionarnos una dirección de correo electrónico actual, precisa y completa, contacto y otra información relacionada con esta Divulgación de firma electrónica y su cuenta y servicios, y mantener y actualizar de inmediato cualquier cambio relacionado con su información de contacto. . Puede actualizar la información de contacto (por ejemplo, su dirección de correo electrónico) en nuestro sitio web, www.apoyo-financiero.com, o comunicándose con nosotros al 1-800-891-2778.<br/></span>,
            txtHistory2014=<span><strong>6. Cómo puede obtener divulgaciones en papel</strong><br/><br/>Puede obtener una copia en papel de una divulgación o notificación electrónica de AFI imprimiéndola usted mismo o solicitando que le enviemos una copia en papel, siempre que dicha solicitud se realice dentro de un tiempo razonable después de que le hayamos enviado la notificación o divulgación electrónica por primera vez. Para solicitar una copia en papel, contáctenos por teléfono al 1-800-891-2778. Nos reservamos el derecho, pero no asumimos ninguna obligación, de proporcionar una copia en papel (en lugar de electrónica) de cualquier comunicación que nos haya autorizado a proporcionar electrónicamente.<br/></span>,
            txtHistory2015=<span><strong>7. Comunicaciones por escrito</strong><br/><br/>Todos los avisos y divulgaciones en formato electrónico o en papel de AFI a usted se considerarán "por escrito". Debe imprimir o descargar para sus registros, una copia de esta Divulgación de firma electrónica, su autorización inicial para recibir notificaciones y divulgaciones electrónicas y cualquier cambio a esa autorización, y cualquier otra comunicación que sea importante para usted.<br/></span>,
            txtHistory216=<span><strong>8. Ley Federal</strong><br/><br/>Usted reconoce y acepta que su consentimiento para las comunicaciones electrónicas se brinda en relación con la Ley Federal de Firmas Electrónicas en el Comercio Global y Nacional (E-Sign), y que está sujeta a ella, y que usted y AFI anticipan que la Ley de Firmas Electrónicas aplicar en la mayor medida posible para validar nuestra capacidad de realizar negocios con usted por medios electrónicos.<br/></span>,
            txtHistory2017=<span><strong>9. Cambios y Terminación</strong><br/></span>,
            txtHistory2018=<span>
                                Al aceptar la Divulgación de firma electrónica, usted:
                                <br/>
                                <ol type="a">
                                    <li>Acepta que su uso del proceso electrónico provisto por AFI para establecer su acuerdo con esta Divulgación de firma electrónica constituye su firma electrónica y significa su intención de estar obligado;<br/><br/></li>
                                    <li>consentimiento para recibir un registro electrónico de divulgaciones y notificaciones de AFI en lugar de una copia impresa o en papel o una versión del mismo, a menos que se solicite específicamente (siempre que su consentimiento para recibir notificaciones o divulgaciones electrónicas se pueda retirar como se describe anteriormente); y<br/><br/></li>
                                    <li>Se reconoce y demuestra su capacidad para acceder electrónicamente a esta Divulgación de firma electrónica y a las divulgaciones y notificaciones electrónicas de AFI. AFI se reserva el derecho, a nuestro exclusivo criterio, de suspender el suministro de sus comunicaciones electrónicas, o de cancelar o cambiar los términos y condiciones en los que proporcionamos notificaciones y divulgaciones electrónicas. Le enviaremos un aviso de dicha terminación o cambio según lo exija la ley.<br/><br/></li>
                                    
                                </ol>
                                </span>,
                       
                    } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtContentValors}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentOne}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentTwo}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentThree}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentFour}                        
                    </Typography>
                    <br/>  
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistoryTwo}                        
                    </Typography>
                    <br/> 
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2014}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2015}                        
                    </Typography>
                    <br/> 
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory216}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2017}                        
                    </Typography>
                    <br/> 
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2018}                        
                    </Typography>
                    <br/> 
                    
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

ElectronicSingCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    txtContentValors: PropTypes.string,
    txtContentOne: PropTypes.string, 
    txtContentTwo: PropTypes.string, 
    txtHistoryOne: PropTypes.string, 
    txtHistoryTwo: PropTypes.string, 
    txtHistory2014: PropTypes.string, 
    txtHistory2015: PropTypes.string, 
    txtHistory2016: PropTypes.string, 
    
}

export default withThemeProps(ElectronicSingCmp, 'VDPNRElectronicsSig')






